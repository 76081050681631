/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cup: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M.11 3.187A.5.5 0 01.5 3h13a.5.5 0 01.488.608l-.22.991a3.001 3.001 0 01-1.3 5.854l-.132.59A2.5 2.5 0 019.896 13H4.104a2.5 2.5 0 01-2.44-1.958L.012 3.608a.5.5 0 01.098-.42zm12.574 6.288a2 2 0 00.866-3.899zM1.124 4l1.516 6.825A1.5 1.5 0 004.104 12h5.792a1.5 1.5 0 001.464-1.175L12.877 4H1.123z"/>',
    },
});
